import HomeView from "@/views/HomeView";

export default [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: 'SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Das Saunaboot im Leipziger Neuseenland. Garantierte Erholung und Wellness auf dem Wasser.'
            }]
        },
    },
    {
        path: '/reservieren/:number?',
        name: 'editReservation',
        component: () => import ('@/views/ReservationEditView'),
        meta: {
            title: 'Reservieren | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Sichere Dir jetzt Dein Saunaboot-Erlebnis.'
            }],
            category: 'reservation',
            event: {
                action: 'reservation_interest'
            }
        }
    },
    {
        path: '/reservierung-bestaetigen',
        name: 'confirmReservation',
        component: () => import ('@/views/ReservationConfirmView'),
        meta: {
            title: 'Bestätige Deine Reservierung | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation',
            event: {
                action: 'reservation_intent'
            }
        }
    },
    {
        path: '/reservierung/:number',
        name: 'viewReservation',
        props: true,
        component: () => import ('@/views/ReservationDetailView'),
        meta: {
            title: 'Deine Reservierung | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/vorschau',
        redirect: '/',
        name: 'preview'
    },
    {
        path: '/erlebnisse',
        name: 'productsAndPrices',
        component: () => import('@/views/ProductsAndPricesView'),
        meta: {
            title: 'Erlebnisse | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Finde das beste Erlebnis für Dich. Vom Romantic Hideaway zu zweit bis zum Wellness-Package für den Freundeskreis ist alles dabei.'
            }],
            category: 'product'
        }
    },
    {
        path: '/produkte',
        redirect: '/erlebnisse',
        name: 'productsAndPrices_old'
    },
    {
        path: '/zusatzprodukte',
        name: 'addProducts',
        component: () => import('@/views/AddProductsView'),
        meta: {
            title: 'Was können wir noch für Dich tun? | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/kunden-daten/:cart',
        name: 'confirmBooking',
        component: () => import ('@/views/BookingConfirmView'),
        meta: {
            title: 'Deine Daten | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/bezahlen/:cartId',
        name: 'payment',
        props: true,
        component: () => import ('@/views/PaymentView'),
        meta: {
            title: 'Kasse | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/bestellung/:orderId',
        name: 'viewOrder',
        props: true,
        component: () => import ('@/views/OrderDetailView'),
        meta: {
            title: 'Was können wir noch für Dich tun? | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/kasse/:cartId?',
        name: 'checkout',
        props: true,
        component: () => import ('@/views/PaymentView'),
        meta: {
            title: 'Kasse | SPABoot - Das schwimmende Sauna-Erlebnis',
            category: 'reservation'
        }
    },
    {
        path: '/agb',
        name: 'termsAndConditions',
        redirect: '/docs/agb.pdf',
    },
    {
        path: '/datenschutz',
        name: 'dataPrivacy',
        props: true,
        component: () => import ('@/views/DataPrivacyView'),
        meta: {
            title: 'Deine Daten bei uns | SPABoot - Das schwimmende Sauna-Erlebnis',
        }
    },
    {
        path: '/info',
        name: 'info',
        props: true,
        component: () => import ('@/views/InfoView'),
        meta: {
            category: 'info',
            title: 'Alle Infos zum Saunaboot-Erlebnis | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Hier findest Du Antworten auf die am meisten gestellten Fragen zu unseren Erlebnissen.'
            }],
        }
    },
    {
        path: '/gutschein',
        name: 'purchaseVoucher',
        props: true,
        component: () => import ('@/views/VoucherPurchaseView'),
        meta: {
            category: 'voucher',
            title: 'Gutscheine, die Geschenk-Idee | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Schenk doch mal ein Wellness-Erlebnis! Unsere Gutscheine sind das perfekte Geschenk für jeden Anlass.'
            }],
        }
    },
    {
        path: '/galerie',
        name: 'gallery',
        props: true,
        component: () => import ('@/views/GalleryView'),
        meta: {
            category: 'gallery',
            title: 'Galerie | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Die schönsten Impressionen eines ganz besonderen Sauna- und See-Erlebnis.'
            }],
        }
    },
    {
        path: '/gastgeber',
        name: 'about',
        props: true,
        component: () => import ('@/views/AboutView'),
        meta: {
            category: 'about',
            title: 'Über uns | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Hier stellen sich Deine Gastgeberin Nicole vor.'
            }],
        }
    },
    {
        path: '/kontakt',
        name: 'contact',
        props: true,
        component: () => import ('@/views/ContactView'),
        meta: {
            title: 'Kontakt | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Was auch immer Du wissen willst: ruf uns an oder schreib uns über das Kontaktformular!'
            }],
        }
    },
    {
        path: '/benachrichtigungen/:subscriptionId',
        name: 'subscriptions',
        props: true,
        component: () => import ('@/views/SubscriptionView'),
        meta: {
            title: 'Benachrichtigungen | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Deine Benachrichtigungseinstellungen'
            }],
        }
    },
    {
        path: '/impressum',
        name: 'imprint',
        props: true,
        component: () => import ('@/views/ImprintView'),
        meta: {
            title: 'Impressum | SPABoot - Das schwimmende Sauna-Erlebnis',
        }
    },
    {
        path: '/erlebnis/:productId',
        name: 'productDetail',
        props: true,
        component: () => import('@/views/ProductDetailView'),
        meta: {
            title: 'Erlebnis-Details | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Hier findest Du alles zu Ablauf und Inklusivleistung unserer Erlebnisse'
            }],
        }
    },
    {
        path: '/specials/weihnachten',
        name: 'xmas24View',
        props: true,
        component: () => import('@/components/specials/xmas24/XMas24SpecialView'),
        meta: {
            title: 'Weihnachten Geschenkbox | SPABoot - Das schwimmende Sauna-Erlebnis',
            metaTags: [{
                name: 'description',
                content: 'Das Geschenk zu Weihnachten. Unsere Gutscheinbox mit Ringana Produkt und Fußbad'
            }],
        }
    }
]

